import React, { useState, useEffect, useRef } from 'react'
import styled from 'styled-components'
import { Button, Menu } from 'antd'
import FeatherIcon from 'feather-icons-react'
import IconWidaga from 'assets/icon/icon-widaga.svg'
import { Link } from 'react-router-dom'

const NavbarContainer = styled.nav`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 120px;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  font-weight: 700;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
  min-height: 80px;

  @media (max-width: 1024px) {
    padding: 10px 120px;
  }

  @media (max-width: 768px) {
    padding: 10px 20px;
    flex-direction: row;
    min-height: 80px;
  }
`

const Logo = styled.div`
  display: flex;
  align-items: center;

  img {
    height: 30px;

    @media (max-width: 860px) {
      height: 30px;
      min-height: 30px;
    }
  }
`

const MenuItems = styled.ul`
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
  flex-grow: 1;
  justify-content: center;

  li {
    margin: 0 15px;
    cursor: pointer;
    font-size: 18px;
    color: #333;
    transition: color 0.3s ease, background-clip 0.3s ease;
    background: linear-gradient(90deg, #333, #333);
    background-clip: text;
    -webkit-background-clip: text;
    color: transparent;

    &:hover {
      background: linear-gradient(90deg, #e48036, #e43a36);
      background-clip: text;
      -webkit-background-clip: text;
      color: transparent;
    }
  }

  @media (max-width: 860px) {
    display: ${({ isOpen }) => (isOpen ? 'block' : 'none')};
    flex-direction: column;
    position: absolute;
    top: 60px;
    left: 0;
    width: 100%;
    background-color: #fff;
    padding: 10px 0;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    z-index: 9999;

    li {
      padding: 10px 20px;
      margin: 0;
      text-align: left;

      &:hover {
        background: linear-gradient(90deg, #e48036, #e43a36);
        background-clip: text;
        -webkit-background-clip: text;
        color: transparent;
      }
    }
  }
`

const Hamburger = styled.div`
  font-size: 24px;
  cursor: pointer;

  @media (min-width: 860px) {
    display: none;
  }
`

const RightSection = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;

  @media (max-width: 860px) {
    flex-grow: 1;
    justify-content: flex-end;
  }
`

const ContactButton = styled(Button)`
  background: linear-gradient(90deg, #f44336, #ff9800);
  color: white;
  font-weight: 700;
  border-radius: 20px;
  padding: 0 20px;
  height: 40px;
  border: none;

  &:hover,
  &:focus {
    background: linear-gradient(90deg, #ff9800, #f44336);
    color: white;
    border: none;
  }

  @media (max-width: 860px) {
    display: none;
  }
`

const LanguageButton = styled(Button)`
  background-color: transparent;
  color: #333;
  font-weight: 600;
  height: 40px;
  border: none;

  &:hover,
  &:focus {
    color: #f44336;
    border-color: transparent;
  }
`

const DropdownMenuWrapper = styled.div`
  position: fixed;
  top: ${({ top }) => top}px;
  left: ${({ left }) => left}px;
  z-index: 1001;
  display: ${({ visible }) => (visible ? 'block' : 'none')};
`

const menuItems = [
  { label: 'Beranda', id: 'home' },
  { label: 'Client', id: 'client' },
  { label: 'Fitur', id: 'features' },
  { label: 'Benefit', id: 'benefit' },
]

const languages = [
  { key: '1', label: 'ID' },
  // { key: '2', label: 'EN' },
]

const SectionHeader = () => {
  const [menuOpen, setMenuOpen] = useState(false)
  const [dropdownOpen, setDropdownOpen] = useState(false)
  const [dropdownPosition, setDropdownPosition] = useState({ top: 0, left: 0 })
  const dropdownRef = useRef(null)
  const hamburgerRef = useRef(null)
  const menuItemsRef = useRef(null)

  const toggleMenu = () => {
    setMenuOpen(!menuOpen)
    setDropdownOpen(false)
  }

  const toggleDropdown = (event) => {
    if (!dropdownOpen) {
      const rect = event.currentTarget.getBoundingClientRect()
      setDropdownPosition({ top: rect.bottom, left: rect.left })
    }
    setDropdownOpen(!dropdownOpen)
    setMenuOpen(false)
  }

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth >= 860) {
        setMenuOpen(false)
      }
    }

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  const handleScroll = (id) => {
    const element = document.getElementById(id)
    if (element) {
      const headerOffset = 70
      const elementPosition = element.getBoundingClientRect().top
      const offsetPosition = elementPosition + window.pageYOffset - headerOffset

      window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth',
      })

      setMenuOpen(false)
    }
  }

  return (
    <NavbarContainer>
      <Logo>
        <Link to="/">
          <img src={IconWidaga} alt="Logo" />
        </Link>
      </Logo>

      <MenuItems ref={menuItemsRef} isOpen={menuOpen}>
        {menuItems.map((item) => (
          <li
            key={item.id}
            onClick={(e) => {
              if (item.id === 'home') {
                window.location.href = '/'
              } else {
                e.stopPropagation()
                handleScroll(item.id)
              }
            }}
          >
            {item.label}
          </li>
        ))}
      </MenuItems>
      <RightSection>
        <LanguageButton
          icon={<FeatherIcon icon="globe" />}
          ref={dropdownRef}
          onClick={toggleDropdown}
        >
          ID
        </LanguageButton>
        <DropdownMenuWrapper
          top={dropdownPosition.top}
          left={dropdownPosition.left}
          visible={dropdownOpen}
          ref={dropdownRef}
        >
          <Menu>
            {languages.map((item) => (
              <Menu.Item
                key={item.key}
                onClick={(e) => {
                  setDropdownOpen(false)
                }}
              >
                {item.label}
              </Menu.Item>
            ))}
          </Menu>
        </DropdownMenuWrapper>
        <Hamburger ref={hamburgerRef} onClick={toggleMenu}>
          <FeatherIcon icon="menu" />
        </Hamburger>
        <ContactButton type="primary">
          <a href="https://api.whatsapp.com/send?phone=6287700290409&text=Halo%2C%20saya%20ingin%20mendapatkan%20informasi%20lebih%20lanjut%20tentang%20produk%20Widaga.%20Terima%20kasih.">
            Hubungi Sales
          </a>
        </ContactButton>
      </RightSection>
    </NavbarContainer>
  )
}

export default SectionHeader
