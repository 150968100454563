import React, { useState } from 'react'
import styled from 'styled-components'
import {
  Loading,
  SectionHero,
  SectionFeatures,
  SectionBenefit,
  SectionCallToAction,
  SectionDemoSchedule,
  SectionClient,
} from 'components/Main'

const Home = () => {
  const [loading, setLoading] = useState(false)

  const ContentWrapper = styled.div`
    padding-top: 80px; /* Tambahkan padding untuk mengimbangi tinggi header */
  `
  setTimeout(() => {
    setLoading(false)
  }, [process.env.REACT_APP_Loading])

  return (
    <>
      {loading ? (
        <Loading type="page" />
      ) : (
        <ContentWrapper>
          <SectionHero option="hero" />
          <SectionClient />
          <SectionFeatures />
          <SectionBenefit />
          <SectionCallToAction />
          <SectionDemoSchedule />
        </ContentWrapper>
      )}
    </>
  )
}
export default Home
